import Base from "../components/base";
import Seo from "../components/Seo";
import Hero from "../components/hero"
import React from "react";
import {useSiteMetadata} from "../hooks/use-sitemeta";
import {graphql, useStaticQuery} from "gatsby";

const Muell = ({pageContext}) => {
  const {siteMetadata} = useSiteMetadata();
  const data = {
    'one': {
      'frontmatter': {
        'title': 'Müll',
        'date': '18.08.2022',
        'image': 'dump.jpg',
        'transcript': true,
        'epno': 'birthdayspecial (Teil 1)',
        'audio': 'muell',
      },
      'html': '<span class="subheading"><p>Im Prinzip bin ich dagegen, dass man den Kindern solche Geschichten auftischt. Aber was soll ich machen? Ich kann nicht gut hinübergehen und dem Mädchen die Wahrheit sagen. Weil furchtbare Geschichte. Aber dir kann ich es ja schnell erzählen.</p></span>'
    },
    'two': {
      'frontmatter': {
        'title': 'Müll',
        'date': '27.08.2022',
        'transcript': true,
        'epno': 'birthdayspecial (Teil 2)',
        'audio': 'muell2',
      },
      'html': '<span class="subheading"><p>Aber der größte Wirbel hält nicht ewig, und nach einer Woche haben sie schon fast nicht mehr darüber geredet. Ich möchte nicht sagen, komplett Schwamm drüber, aber das Leben geht weiter. Und es war sogar eine bessere Stimmung bei der Arbeit als sonst.</p></span>'

  },
  'three': {
    'frontmatter': {
      'title': 'Müll',
      'date': '11.11.2022',
      'transcript': true,
      'epno': 'birthdayspecial (Teil 3)',
      'audio': 'muell3',
    },
    'html': '<span class="subheading"><p>Sie hat so ein Lachen ausgestoßen, von dem der Brenner eine Gänsehaut bekommen hat. Ein vollkommen klangloses Lachen war das, fast hätte ich gesagt, tonlos, aber tonlos war es ja nicht, weil die Luft macht ja doch einen Hauch von einem Ton.</p></span>'
},
    'four': {
      'frontmatter': {
        'title': 'Müll',
        'date': '18.12.2022',
        'transcript': false,
        'epno': 'birthdayspecial (Teil 4)',
        'audio': 'muell4',
      },
      'html': '<span class="subheading"><p>Wenn du als vertriebener Bettgänger nicht sofort wieder in eine Wohnung einziehst, schaffst du es vielleicht nie wieder, sprich Traumabewältigung. Da gilt für den Bettgeher dieselbe Regel wie für das normale Einbruchsopfer. Du musst hart zu dir sein, das kriegt jedes Einbruchsopfer vom Polizeipsychologen eingehämmert.</p></span>'
    }


};
  const image = useStaticQuery(graphql`
      {
        img: file(relativePath: {eq: "images/dump.jpg"}) {
              childImageSharp {
                    fluid (quality: 90, maxWidth: 700) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
              }
        }
        scale: file(relativePath: {eq: "images/scale.jpg"}) {
              childImageSharp {
                    fluid (quality: 90, maxWidth: 700) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
              }
        }
        laugh: file(relativePath: {eq: "images/laugh.jpg"}) {
              childImageSharp {
                    fluid (quality: 90, maxWidth: 700) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
              }
        }
        pig: file(relativePath: {eq: "images/schwein.jpg"}) {
              childImageSharp {
                    fluid (quality: 90, maxWidth: 700) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
               }
            }


      }
  `);

  return (<Base special>
    <Seo article image={siteMetadata.defaultImage}
         description={siteMetadata.description}
         title="Müll"
         pathname={pageContext.slug}/>
    <Hero image={image.pig.childImageSharp.fluid} siteMeta={siteMetadata} data={data.four}/>
    <Hero image={image.laugh.childImageSharp.fluid} siteMeta={siteMetadata} data={data.three}/>
    <Hero image={image.scale.childImageSharp.fluid} siteMeta={siteMetadata} data={data.two}/>
    <Hero image={image.img.childImageSharp.fluid} siteMeta={siteMetadata} data={data.one}/>
  </Base>)
}
export default Muell;
